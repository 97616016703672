import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/layout";
import Seo from "../../components/Seo";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <Seo title="Contact Us" description="Get In Touch!" />
        <section className="section">
          <div className="w-full max-w-screen-xl px-6 mx-auto">
            <div className="content">
              <div className={`text-center md:text-left pt-8 pb-12 md:py-16`}>
                <h1 className="lg:pr-12">Contact Us</h1>
              </div>
              <div className={"flex flex-wrap w-full md:flex-nowrap"}>
                <div
                  className={
                    "w-full md:w-1/2 md:pr-10 lg:pr-24"
                  }
                >
                  <div
                    className={
                      "border border-[#8FDFBC] p-8 text-center h-full flex flex-col justify-center"
                    }
                  >
                    <div className={"mb-8"}>
                      <strong className={"font-medium block text-xl mb-2"}>email</strong>
                      <a
                        className={
                          "text-lg border-b-2 border-black hover:border-[#72e6bd] transition py-1"
                        }
                        href="mailto:sarah@findurfit.com"
                      >
                        sarah@findurfit.com
                      </a>
                    </div>
                    <div className={"mb-8"}>
                      <strong className={"font-medium block text-xl mb-2"}>mobile</strong>
                      <a
                        className={
                          "text-lg border-b-2 border-black hover:border-[#72e6bd] transition py-1"
                        }
                        href="tel:00447887531266"
                      >
                        07887 531266
                      </a>
                    </div>
                    <div className={"mb-8"}>
                      <strong className={"font-medium block text-xl mb-2"}>facebook</strong>
                      <a
                        className={
                          "text-lg border-b-2 border-black hover:border-[#72e6bd] transition py-1"
                        }
                        href="https://facebook.com/FindurFitUK"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        @FindurFitUK
                      </a>
                    </div>
                    <div className={"mb-8"}>
                      <strong className={"font-medium block text-xl mb-2"}>instagram</strong>
                      <a
                        className={
                          "text-lg border-b-2 border-black hover:border-[#72e6bd] transition py-1"
                        }
                        href="http://instagram.com/FindurFitUK"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        @FindurFitUK
                      </a>
                    </div>
                  </div>
                </div>
                <div className="relative w-full my-10 md:my-0 md:pl-10 md:w-1/2">
                  <form
                    name="contact"
                    method="post"
                    action="/contact/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{" "}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className="field">
                      <label
                        htmlFor="name"
                        className="block mb-4 uppercase"
                      >
                        Name
                      </label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        onChange={this.handleChange}
                        // value={formState.name}

                        className="placeholder:text-grey w-full block focus:ring-0 focus:ring-offset-0 outline-none mb-8 border-b-[1.5px] border-black pb-1"
                        required={true}
                      />
                    </div>
                    <div className="field">
                      <label
                        htmlFor="email"
                        className="block mb-4 uppercase"
                      >
                        Email
                      </label>
                      <div className="control">
                        <input
                          className="focus:placeholder:text-transparent placeholder:text-grey w-full focus:ring-0 focus:ring-offset-0 outline-none mb-8 border-b-[1.5px] border-black pb-1"
                          type={"email"}
                          name={"email"}
                          onChange={this.handleChange}
                          id={"email"}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label
                        htmlFor={"message"}
                        className="block mb-4 uppercase"
                      >
                        Message
                      </label>
                      <div className="control">
                        <textarea
                          className="focus:placeholder:text-transparent placeholder:text-grey placeholder:text-xs md:placeholder:text-lg w-full focus:ring-0 focus:ring-offset-0 outline-none mb-8 border-b-[1.5px] border-black"
                          name={"message"}
                          rows="7"
                          onChange={this.handleChange}
                          id={"message"}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <button
                        type="submit"
                        className="px-8 py-1 text-base text-[#72e6bd] uppercase border cursor-pointer text-light-green border-black"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
